import { FORM_MANAGEMENT_NAMES } from '@storefrontFeatures/form_management/form_management_constants';
import { RECAPTCHA_NAMES } from '@storefrontRoot/features/recaptcha/recaptcha_constants';
import { RESOLUTION_DETECTOR_NAMES } from '@storefrontRoot/features/resolution_detector/resolution_detector_constants';
import { TDynamicFeatureCoreInitializer } from '@dreamcommerce/star_core';
import { BASKET_NAMES } from '@storefrontFeatures/basket/basket_constants';
import { AUTHENTICATION } from '@storefrontFeatures/authentication/authentication_constants';
import { USER_NAMES } from '@storefrontFeatures/user/user_constants';
import { TRANSLATION_NAMES } from '@storefrontFeatures/translations/translations_initializer_constants';
import { PAGE_MANAGER_NAMES } from '@storefrontCoreFeatures/page_management/page_manager_constants';
import { ANALYTICS_NAMES } from '@storefrontFeatures/analytics/analytics_constants';
import { PRODUCT_FETCHER_NAMES } from '@storefrontFeatures/product_fetcher/product_fetcher_constants';
import { INPOST_PAY_NAMES } from '@storefrontFeatures/inpost_pay/inpost_pay_constants';

export const STOREFRONT_DYNAMIC_FEATURES_RESOLVERS = [
    {
        featureName: ANALYTICS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/analytics/analytics_initializer');
        },
        autoInit: true
    },
    {
        featureName: RESOLUTION_DETECTOR_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/resolution_detector/resolution_detector_initializer');
        },
        autoInit: true
    },
    {
        featureName: TRANSLATION_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/translations/translations_initializer');
        },
        autoInit: true
    },
    {
        featureName: PAGE_MANAGER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontCoreFeatures/page_management/page_manager_initializer');
        },
        autoInit: false
    },
    {
        featureName: FORM_MANAGEMENT_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/form_management/form_management_initializer');
        },
        autoInit: false
    },
    {
        featureName: RECAPTCHA_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/recaptcha/recaptcha_initializer');
        },
        autoInit: true
    },
    {
        featureName: AUTHENTICATION.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/authentication/authentication_initializer');
        },
        autoInit: true
    },
    {
        featureName: USER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/user/user_initializer');
        },
        autoInit: true
    },
    {
        featureName: BASKET_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/basket/basket_initializer');
        },
        autoInit: false
    },
    {
        featureName: PRODUCT_FETCHER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/product_fetcher/product_fetcher_initializer');
        },
        autoInit: true
    },
    {
        featureName: INPOST_PAY_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/inpost_pay/inpost_pay_initializer');
        },
        autoInit: false
    }
];
