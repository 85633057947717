const featureName = 'Analytics';

export const ANALYTICS_NAMES = {
    feature: featureName,
    component: '',
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;

export const GOOGLE_ANALYTICS_CONFIG_SELECTOR = '.js__google-analytic-config';
export const FACEBOOK_BUSINESS_EXTENSION_CONFIG_SELECTOR = '.js__fbe-config';
export const GOOGLE_ADS_CONFIG_SELECTOR = '.js__google-ads-config';
export const MICROSOFT_ADS_CONFIG_SELECTOR = '.js__microsoft-ads-config';
