import 'reflect-metadata';

import { API_RESOLVER_QUERIES, EventMessage, IFeatureApi, IStarCoreEnvironment, QueryMessage, StarCoreSetup } from '@dreamcommerce/star_core';

import { COMPONENTS_CONTAINER_NAME } from '@storefrontCoreFeatures/components/management/components_management_constants';
import { STOREFRONT_DYNAMIC_COMPONENTS_MAPPER } from '@storefrontCoreFeatures/components/management/storefront_dynamic_components_mapper';
import { STOREFRONT_DYNAMIC_FEATURES_RESOLVERS } from '@storefrontApp/initializer/storefront_dynamic_features_mapper';
import { STOREFRONT_FEATURES_TO_INITIALIZE } from '@storefrontApp/initializer/core_features_to_initialize';
import { PAGE_MANAGER_EVENTS } from '@storefrontCoreFeatures/page_management/turbo_message_names';

window.useStorefront =
    window.useStorefront ||
    function (callback: any) {
        // @ts-ignore
        window.useStorefront.callbacks.push(callback);
    };
// @ts-ignore
window.useStorefront.callbacks = [];

export class StorefrontApp {
    constructor() {
        this._init();
    }

    private async _init(): Promise<void> {
        try {
            await StarCoreSetup.setup({
                features: [...STOREFRONT_FEATURES_TO_INITIALIZE],
                dynamicInitializersMap: [...STOREFRONT_DYNAMIC_FEATURES_RESOLVERS, ...STOREFRONT_DYNAMIC_COMPONENTS_MAPPER],
                hooks: {
                    onSetupStart(environment: IStarCoreEnvironment) {
                        const { globalCoresContainersRegistry } = environment;

                        globalCoresContainersRegistry.registerCustomContainer(COMPONENTS_CONTAINER_NAME);
                    },

                    onSetupFinished(environment: IStarCoreEnvironment) {
                        const { queryBus, commandBus, eventBus } = environment;

                        async function getApi<A extends IFeatureApi>(apiName: string): Promise<A | undefined> {
                            const api = await queryBus.execute<QueryMessage<string>, A>(new QueryMessage(API_RESOLVER_QUERIES.getApi, apiName));

                            if (!api) return undefined;

                            return api;
                        }

                        function getApiSync<A extends IFeatureApi>(apiName: string): A | undefined {
                            const api = queryBus.executeSync<QueryMessage<string>, A>(
                                new QueryMessage(API_RESOLVER_QUERIES.getApiSync, apiName)
                            );

                            if (!api) return undefined;

                            return api;
                        }

                        function hasApi(apiName: string): boolean {
                            const hasApi = queryBus.executeSync<QueryMessage<string>, boolean>(
                                new QueryMessage(API_RESOLVER_QUERIES.hasApi, apiName)
                            );

                            return !!hasApi;
                        }

                        // @ts-ignore
                        window.useStorefront.callbacks.forEach((callback) => {
                            callback({ getApi, getApiSync, hasApi, eventBus, commandBus, queryBus });
                        });

                        // @ts-ignore
                        window.useStorefront = (callback) => {
                            callback({ getApi, getApiSync, hasApi, eventBus, commandBus, queryBus });
                        };

                        eventBus.emit(new EventMessage(PAGE_MANAGER_EVENTS.loaded));
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
}

/**
 * @kossak wydaje mi sie ze trzeba to przytrzymać w window bo nie mam pewności czy te
 globalne managery oraz busy przetrwaja, jeżeli zdaży sie sytuacja ze jakois komponent/moduł tego nie bedzie gdzieś importował, do sprawdzenia
 */
/**
 * TODO @zefirek sprawdzić jak turbolinks bedzie czyścił pamieć w window, oraz czy on odpali jeszcze raz tego appa, jak
 * nie to przenisc incjalizacje managerów oraz busów gdzieś indziej i wpinać do body, bo body sie czyści, ale to
 * zbadania, also przydałby sie jakiś cleaner do wyczyszczenia tego, ev sam kiner który sie odpali jak zawartosc body
 * sie zmienic
 */

if (document.readyState === 'interactive') {
    window.app = new StorefrontApp();
} else {
    document.addEventListener('DOMContentLoaded', () => {
        window.app = new StorefrontApp();
    });
}
