const featureName = 'blog-article-comment-form';

export const BLOG_ARTICLE_COMMENT_FORM_NAMES = {
    feature: featureName,
    component: featureName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: ''
} as const;
